/* eslint-disable @next/next/no-img-element */
import useWindowSize from '@hooks/useWindowSize'
import { defaultImages } from '@lib/variables'
import { useInView } from 'react-intersection-observer'
import { useEffect, useState } from 'react'

const Image = ({ src, alt, imgMobile, fallbackImage, quickload, ...props }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '200px 0px 200px 0px',
  })
  const windowSize = useWindowSize()

  const [url, setUrl] = useState(
    src || fallbackImage || defaultImages.mediumNavy
  )

  useEffect(() => {
    let newUrl = url

    if (windowSize?.width <= 767) {
      newUrl = imgMobile || src
    } else {
      newUrl = src
    }

    if (newUrl !== url) {
      setUrl(newUrl)
    }
  }, [src, imgMobile, fallbackImage, windowSize?.width])

  return quickload ? (
    <img
      {...props}
      alt={alt}
      src={url}
      onError={() => setUrl(fallbackImage || defaultImages.mediumNavy)}
    />
  ) : (
    <div ref={ref} style={{ lineHeight: 0 }}>
      {inView && (
        <img
          {...props}
          loading={quickload ? 'eager' : 'lazy'}
          alt={alt}
          key={url}
          src={url}
          onError={() => setUrl(fallbackImage || defaultImages.mediumNavy)}
        />
      )}
    </div>
  )
}

export default Image
