import { Link } from '@routes'
import Image from '@global/Image'
import FavouriteHeart from '@global/FavouriteHeart'
import IconPhone from '@icons/Phone'
import IconEmail from '@icons/Email'
import Button from '@global/Button'
import IconArrowRight from '@icons/ArrowRight'
import * as S from './OfficeCard.styled'

const OfficeCard = (props) => {
  const {
    id,
    link,
    img,
    name,
    address,
    mapResults,
    phoneLabel,
    phone,
    latitude,
    longitude,
    profileLink,
    directionsLink,
    onClickEnquire,
    showFavourite = true,
  } = props

  return (
    <S.OfficeCard
      data-gtm-office-name={name}
      data-gtm-office-id={id}
      mapResults={mapResults}
    >
      <S.OfficeCardImage>
        <Image src={img?.url} alt={name} />
        {showFavourite && <FavouriteHeart type='office' id={id} />}
      </S.OfficeCardImage>

      <S.OfficeCardHeading>
        <S.OfficeName>
          <Link route={profileLink.link || link} passHref>
            {name}
          </Link>
        </S.OfficeName>
        <S.OfficeAddress>{address}</S.OfficeAddress>
      </S.OfficeCardHeading>

      <S.OfficeCardInfo>
        <div>
          <S.OfficeCardContact>
            {phone ? (
              <>
                <IconPhone />
                <a href={`tel:${phone}`}>{phone}</a>
              </>
            ) : (
              <>&nbsp;</>
            )}
          </S.OfficeCardContact>
          <S.OfficeCardContact
            onClick={() => onClickEnquire && onClickEnquire(props)}
          >
            <IconEmail />
            <span>Enquire</span>
          </S.OfficeCardContact>
        </div>
        <Button circle>
          <IconArrowRight />
        </Button>
      </S.OfficeCardInfo>
      <Link route={link} passHref></Link>
    </S.OfficeCard>
  )
}

OfficeCard.defaultProps = {}

export default OfficeCard
