import { useContext, useState, useEffect } from 'react'
import { Router } from '@routes'
import { UserContext } from '@context/UserContext'
import { getCookie, ACTIVEPIPE_COOKIE } from '@lib/cookies'
import Button from '@global/Button'
import IconPadlock from '@icons/Padlock'
import * as S from './OffMarketOverlay.styled'

const OffMarketOverlay = ({ title, buttonLabel, returnName, returnPath }) => {
  const [unlocked, setUnlocked] = useState(true)
  const { setReturnto, token, toggleFavouriteModal } = useContext(UserContext)
  const apCookie = getCookie(ACTIVEPIPE_COOKIE)

  useEffect(() => {
    if (!token && !apCookie) {
      setUnlocked(false)
    }
  }, [token, apCookie])

  const handleClick = async (e) => {
    e.preventDefault()
    if (returnName && returnPath) {
      await setReturnto({
        name: returnName,
        path: returnPath,
      })
    }

    toggleFavouriteModal()
  }

  return (
    <S.OffMarketOverlay unlocked={unlocked}>
      <p>{title}</p>
      {!unlocked && (
        <Button
          onClick={handleClick}
          color='primary'
          size='sm'
          startIcon={<IconPadlock />}
        >
          {buttonLabel}
        </Button>
      )}
    </S.OffMarketOverlay>
  )
}

OffMarketOverlay.defaultProps = {
  title: 'Off Market',
  buttonLabel: 'Sign in to unlock',
  buttonHoverLabel: 'Unlock',
}

export default OffMarketOverlay
