import { useState } from 'react'
import Button from '../Button'
import IconPlusBig from '../Icons/PlusBig'
import PropertyFiltersForm from './FiltersForm'
import Autocompete from './Autocompete'
import { Formik } from 'formik'
import { UrlBuilder } from '@lib/helpers'
import { Router } from '@routes'
import { PROPERTY_CLASS, PROPERTY_RESULTS_PATH } from '@constants/property'
import * as S from './PropertySearchFrom.styled'

const LISTING_TYPE = {
  sale: {
    value: 'sale',
    desktop: 'For Sale',
    mobile: 'Buy',
    isShow: true,
  },
  lease: {
    value: 'lease',
    desktop: 'For Lease',
    mobile: 'Lease',
    isShow: true,
  },
  sold: {
    value: 'sold',
    desktop: 'Sold Results',
    mobile: 'Sold',
    isShow: true,
  },
}

const PropertySearchFrom = ({ searchPages, submitTo, homeSearchType = 'all' }) => {
  const [isShowFilters, setShowFilters] = useState(false)

  // Update isShow status based on home_search_type
  const getUpdatedListingTypes = () => {
    const updatedListingTypes = { ...LISTING_TYPE }

    if (homeSearchType === 'sale') {
      updatedListingTypes.lease.isShow = false
    } else if (homeSearchType === 'lease') {
      updatedListingTypes.sale.isShow = false
      updatedListingTypes.sold.isShow = false
    }
    return updatedListingTypes
  }

  const [listingTypes, setListingTypes] = useState(getUpdatedListingTypes());
  const [activeTab, setActiveTab] = useState(listingTypes.sale.isShow ? 'sale' : 'lease')

  let SearchType = null
  if (["commercial_rent", "residential_rent"].includes(searchPages)) {
    SearchType = "lease"
  }else if (["commercial_sale", "residential_sale"].includes(searchPages)) {
    SearchType = "sale"
  }else if(searchPages == "project") {
    SearchType = "project"
  }
  const toggleFilters = () => {
    setShowFilters((prev) => !prev)
  }

  const onSubmit = async (values) => {
    let params = { ...values }
    if (!params.order_by) {
      params.order_by = '-created'
    }
    if (params.listing_type === 'sold') {
      params.status = 'sold'
    }
    if (params.property_class === PROPERTY_CLASS.RESIDENTIAL) {
      delete params.area__gte
      delete params.area__lte
    } else {
      // commercial
      delete params.bathrooms__gte
      delete params.bathrooms__lte
      delete params.bedrooms__gte
      delete params.bedrooms__lte
      delete params.hide_under_offer
    }

    if (params.surrounding === false) {
      delete params.surrounding
      delete params.bordering
      delete params.surrounds
    }

    setShowFilters(false)

    let submitUrl = PROPERTY_RESULTS_PATH[values.listing_type]
    if (!!params.listing_type || params.status === 'sold') {
      if (submitTo) {
        // use "submit to" if search is in component block
        submitUrl = submitTo.path
      } else if (searchPages) {
        // if search pages exist as prop - determine correct
        // submit URL based on search form criteria
        if (params.status === 'sold' && searchPages.sold_search) {
          submitUrl = searchPages.sold_search
        } else if (
          params.property_class &&
          params.property_class == 'commercial'
        ) {
          // commercial search
          submitUrl =
            // is there a commercial search page?
            searchPages.commercial_search
              ? searchPages.commercial_search
              : // if no - is listing type sale?
              params.listing_type && params.listing_type == 'sale'
              ? searchPages.residential_sale_search || submitUrl
              : // if no - is listing type lease?
              params.listing_type && params.listing_type == 'lease'
              ? searchPages.residential_rent_search || submitUrl
              : // otherwise, leave as current URL
                searchPages.global_search
        } else if (
          params.property_class &&
          params.property_class == 'project'
        ) {
          // projects search
          submitUrl = searchPages.projects_search || submitUrl
        } else if (
          params.listing_type &&
          params.listing_type == 'sale' &&
          params.status != 'sold'
        ) {
          // buy (sale) search
          submitUrl = searchPages.residential_sale_search || submitUrl
        } else if (
          params.listing_type &&
          params.listing_type == 'lease' &&
          params.status != 'sold'
        ) {
          // rent (lease) search
          submitUrl = searchPages.residential_rent_search || submitUrl
        }

        // Failover to a global serach results page
        if (!submitUrl) {
          submitUrl = searchPages.global_search
        }
      }
    }

    delete params.listing_type
    await Router.pushRoute(UrlBuilder(submitUrl, params))
  }

  return (
    <>
      <Formik
        initialValues={{
          property_class: PROPERTY_CLASS.RESIDENTIAL,
          categories: [],
          listing_type: 'sale',
          surrounding: true,
          surrounds: true,
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setValues, setFieldValue }) => (
          <>
            <S.PropertySearchFrom noValidate onSubmit={handleSubmit}>
              <S.FilterOptions>
                {Object.keys(listingTypes).map((key) => (
                  LISTING_TYPE[key].isShow && (!SearchType || SearchType === key) && (
                  <S.FilterOptionItem
                    active={activeTab === key}
                    key={key}
                    onClick={() => {
                      setActiveTab(key)
                      setFieldValue('listing_type', key)
                    }}
                  >
                    <span>{LISTING_TYPE[key].desktop}</span>
                    <span>{LISTING_TYPE[key].mobile}</span>
                  </S.FilterOptionItem>
                  )
                ))}
              </S.FilterOptions>
              <S.FormGroup>
                <Autocompete
                  className='search-input'
                  onChange={(data) => {
                    setValues({ ...values, ...data })
                  }}
                />
                <S.SelectedFilters onClick={toggleFilters}>
                  Filter <IconPlusBig />
                </S.SelectedFilters>
                <Button color='secondary' size='big' className={'btn-search'}>
                  Search
                </Button>
              </S.FormGroup>
              <S.FormGroupMobile>
                <Autocompete
                  className='search-input'
                  onChange={(data) => {
                    setValues({ ...values, ...data })
                  }}
                  iconSearchClick={handleSubmit}
                  toggleFilters={toggleFilters}
                  positionMenu={'top'}
                  showFilters
                />
              </S.FormGroupMobile>
              <PropertyFiltersForm
                open={isShowFilters}
                toggleFilters={toggleFilters}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                initialValues={values}
              />
            </S.PropertySearchFrom>
          </>
        )}
      </Formik>
    </>
  )
}

export default PropertySearchFrom
