import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import SVGIcon from '../../icon/svgIcon'
import { DynamicFormData } from './contanst'
import { submitCMSForm, getFormsFields } from '@lib/api-services/formsService'
import { dynamicValidation } from '@lib/helpers/form'
import { toast } from 'react-toastify'
import { logEvent } from '@lib/analyticsService'
import { Router } from '@routes'
import { Col, Container, Row } from '@styles/global.styled'
import Field from '@global/FormInput/Field'
import Button from '@global/Button'
import Spinner from '@global/Spinner'
import * as S from './DynamicForm.styled'
import nProgress from 'nprogress'

const DynamicForm = (props) => {
  const { slug, layout, formReference } = props
  const [isShowing, setIsShowing] = useState(false)
  const [formData, setFormData] = useState({})

  const submitForm = async (values, setSubmitting) => {
    const { success_message, error_message, title } = formData

    nProgress.start()
    setSubmitting(true)
    submitCMSForm(slug, {
      ...values,
      'block-reference': formReference,
      page: Router.router.query.slug,
    }).then((res) => {
      if (res.ok) {
        // GA: log form submission in google analytics
        logEvent('Forms', title, window.location.pathname)
        toast.success(success_message)
        setSubmitting(false)
        setIsShowing(false)
      } else {
        toast.error(error_message)
        setSubmitting(false)
      }
      nProgress.done()
    })
  }

  const createSelectOptions = (options) => {
    if (!options || !options.length) return

    let selectOptions = []

    options.map((opt) => {
      selectOptions.push({
        value: opt[0],
        label: opt[1],
      })
    })

    return selectOptions
  }

  useEffect(() => {
    getFormsFields(slug).then((formFields) => {
      let formData = DynamicFormData[slug] || DynamicFormData.default
      formData.leftFields = []
      formData.rightFields = []
      formData.formSubmit = formFields.submit
      formData.validateSchema = {}
      formData.title = formFields.title
      formData.success_message = formFields.success_message
      formData.error_message = formFields.error_message
      formData.initialValues = {}

      if (formFields) {
        formFields.form?.every((field) => {
          delete field.value
          if (field.type === 'select')
            field.options = createSelectOptions(field.choices)

          if (formData.leftFieldsName.find((s) => field.name == s)) {
            formData.leftFields.push(field)
          }
          if (formData.rightFieldsName.find((s) => field.name == s)) {
            formData.rightFields.push(field)
          }
          return true
        })
      }
      formData.validateSchema = dynamicValidation(formFields.form)
      formData.leftFields.map((field) => {
        const defaultVal =
          field.options && field.options[0] ? field.options[0].value : ''
        formData.initialValues[field.name] = defaultVal
      })

      setFormData({ ...formData })
      setIsShowing(true)
    })
  }, [slug])

  const {
    title,
    leftFields,
    rightFields,
    formSubmit,
    socialLinks,
    initialValues,
    validateSchema,
  } = formData

  return (
    isShowing && (
      <Formik
        validationSchema={validateSchema}
        onSubmit={(values, { setSubmitting }) => {
          submitForm(values, setSubmitting)
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit, isSubmitting, getFieldProps, errors, touched }) => (
          <S.DynamicForm>
            <Container>
              <form noValidate onSubmit={handleSubmit}>
                <S.DynamicFormHeader>
                  <h3>{title}</h3>
                  {socialLinks?.length > 0 && (
                    <ul>
                      {socialLinks.map((link, i) => (
                        <li key={`socialLink-${i}`}>
                          <a href={link.url}>
                            <SVGIcon name={link.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </S.DynamicFormHeader>
                <Row gutter={16}>
                  <Col md={layout === 'single_column' ? 12 : 6}>
                    {leftFields?.map(
                      (field, index) =>
                        field && (
                          <Field
                            key={`leftFields-${index}`}
                            {...field}
                            {...getFieldProps(field.name)}
                            placeholder={field.help_text}
                            error={touched[field.name] && errors[field.name]}
                          />
                        )
                    )}
                  </Col>
                  <Col md={layout === 'single_column' ? 12 : 6}>
                    {rightFields?.map(
                      (field, index) =>
                        field && (
                          <Field
                            key={`rightFields-${index}`}
                            {...field}
                            {...getFieldProps(field.name)}
                            placeholder={field.help_text}
                            error={touched[field.name] && errors[field.name]}
                          />
                        )
                    )}
                  </Col>
                </Row>
                {formSubmit ? (
                  <Button color='primary' type='submit' disabled={isSubmitting}>
                    {formSubmit.label ?? 'Submit'}
                  </Button>
                ) : null}
              </form>
            </Container>
            {isSubmitting && <Spinner />}
          </S.DynamicForm>
        )}
      </Formik>
    )
  )
}

DynamicForm.defaultProps = {}

// eslint-disable-next-line react/display-name
DynamicForm.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return null
  }

  return {
    slug: data.value.slug,
    formReference: data.value.form_reference,
    layout: data.value.layout,
  }
}

export default DynamicForm
